import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SelectorsTablePlot from "./SelectorsTablePlotAbstract.js"; // Assuming this is the updated Selectors component
import "./Page.css";
import FeedbackForm from "./FeedBackForm.js";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index.js";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "../../../components/Footer/Footer";

const AbstractPage = ({
                        initialUnitOp,
                        initialProcessLocation,
                        initialSampleLocation,
                        initialAnalyte,
                        initialStartTime,
                        initialEndTime,
                      }) => {
  const [unitOp, setUnitOp] = useState(initialUnitOp);
  const [processLocation, setProcessLocation] = useState(initialProcessLocation);

  // Use the useEffect hook to re-render the component when initialUnitOp or initialProcessLocation change
  useEffect(() => {
    setUnitOp(initialUnitOp);
    setProcessLocation(initialProcessLocation);
  }, [initialUnitOp, initialProcessLocation]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <SelectorsTablePlot initialProcessLocation={processLocation} initialUnitOp={unitOp} />
        </MDBox>
        <FeedbackForm />
        <Footer />
      </DashboardLayout>
    </>
  );
};

AbstractPage.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
};

export default AbstractPage;
