import { LabSheetPage } from "./LabSheetPage";
import analyteLimits from "../../data/analyteLimits.json";

const analytes = Object.keys(analyteLimits);
const sampleLocations = [
  "Evap Thick Juice",
  "Hi Raw Melter",
  "Std Liquor",
  "Hi Raw Storage",
  "Low Raw Storage",
  "Mol Produced",
  "White Pan",
  "Aff Fillmass",
  "Affination Syr",
  "Hi Raw Pan",
  "Hi Raw Storage",
  "Low Raw",
  "Low Raw Storage",
  "Sug Produced",
  "Thick Jce fr Stor",
  "White Sugar",
];


export const SugarEndPage = () => {
  return <LabSheetPage sampleLocations={sampleLocations} analytes={analytes} />;
};
