import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import LabDashNavBar from "../../examples/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import Footer from "../../components/Footer/Footer";


type PageProps = {
  children: ReactNode;
}

export const Page = ({ children }: PageProps) => {
  return (
    <>
      <DashboardLayout>
        <Box py={3}>
          <LabDashNavBar />
          {children}
        </Box>
        <Footer />
      </DashboardLayout>
    </>
  );
};