import {
  convertSample,
  Sample,
  SampleFilter,
  SampleResponse,
  streamFilteredSamples,
} from "../handlers/samples";
import { useState } from "react";
import dayjs from "dayjs";
import { getOperatingWindow, OperatingWindow } from "../handlers/operatingWindow";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useQuery } from "@tanstack/react-query";

dayjs.extend(utc);
dayjs.extend(timezone);

export type AnalyteHourGrouping = {
  sampleLocation: string;
  analyte: string;
  values: Record<string, number[]>;
  operatingWindow?: OperatingWindow;
};

export function useTimeSeriesSampleStream(filter: SampleFilter) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["timeSeriesSampleStream", filter],
    queryFn: async () => {
      console.log("filter", filter);
      const samples = await streamFilteredSamples(filter);
      if (samples instanceof Error) {
        throw samples;
      }
      let resp: AnalyteHourGrouping[] = [];
      for (const sample of samples) {
        resp = updateData(resp, sample);
      }

      return resp;
    },
  });

  const updateData = (into: AnalyteHourGrouping[], sample: SampleResponse) => {
    const dateHour = dayjs.utc(sample.collectDT).local().format("YYYY-MM-DDTHH");

    const existingRecord = into.find(
      (record) =>
        record.sampleLocation === sample.sampleLocation && record.analyte === sample.analyte
    );

    if (existingRecord) {
      const updatedValues = {
        ...existingRecord.values,
        [dateHour]: [...(existingRecord.values[dateHour] || []), sample.resultValue],
      };
      return into.map((record) =>
        record === existingRecord ? { ...record, values: updatedValues } : record
      );
    } else {
      const newRecord: AnalyteHourGrouping = {
        sampleLocation: sample.sampleLocation,
        analyte: sample.analyte,
        values: { [dateHour]: [sample.resultValue] },
        operatingWindow: getOperatingWindow({
          sampleLocation: sample.sampleLocation,
          analyte: sample.analyte,
        }),
      };
      return [...into, newRecord];
    }
  };

  return { data, isLoading, isError };
}
