import analyteLimits from "../../data/analyteLimits.json";
import sampleLocToAnalyte from "../../data/sampleLocToAnalyte.json";
import { LabSheetPage } from "./LabSheetPage";

const analytes = Object.keys(analyteLimits);

const sampleLocations = sampleLocToAnalyte.map((loc) => loc.name).filter((loc) => loc !== "None");

export const FacilityPage = () => {
  return LabSheetPage({ sampleLocations, analytes });
};
