import { Page } from "../../layouts/pages/Page";
import { SampleTimeSeriesTable } from "../../components/Samples/SampleTimeSeriesTable";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

interface LabSheetPageProps {
  sampleLocations: string[];
  analytes: string[];
}

export const LabSheetPage = ({ sampleLocations, analytes }: LabSheetPageProps) => {
  return (
    <Page>
      <SampleTimeSeriesTable
        sampleLocations={sampleLocations}
        analytes={analytes}
      />
    </Page>
  );
};
